import styles from "./styles.module.scss";
import classnames from "classnames";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";

const SlickBanner = () => {
  const [bannerData, setBannerData] = useState<any>(null);
  const slickBanner: any = useRef();

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await fetch("/api/hubspot-cms/slick-banner-cms");
        const data = await response.json();
        setBannerData(data.data);
      } catch (err) {
        console.error("Banner fetch error:", err);
      }
    };

    fetchBannerData();
  }, []);

  const stringToHTML = function (str: string) {
    const dom = document.createElement("div");
    dom.innerHTML = str;
    return dom;
  };

  useEffect(()=>{
      const observable = new IntersectionObserver(
        ([entry]) => {
          if (entry?.isIntersecting) {
            selectedPromotionBanner("view_promotion");
            observable?.unobserve(slickBanner.current);
          }
        },
        { threshold: 0.2 }
      );
      if (slickBanner.current) {
        observable?.observe(slickBanner.current);
      }
      return () => {
        if (slickBanner.current) {
          observable?.unobserve(slickBanner.current);
        }
      };
  },[slickBanner.current])

  const selectedPromotionBanner = (event: string) => {
    const bannerText = stringToHTML(bannerData?.bannertitle || "");
    window?.dataLayer?.push({ ecommerce: null });
    window?.dataLayer?.push({
      event: event,
      ecommerce: {
        items: [
          {
            promotion_id: 0,
            promotion_name: bannerText?.innerText?.replace("\n", "") || "",
            creative_name: bannerText?.innerText?.replace("\n", "") || "",
            creative_slot: 1
          }
        ]
      }
    });
  };

  if (!bannerData?.bannertitle) return null;

  return (
    <div ref={slickBanner} className={classnames(styles.bannerContainer, "bg-oxfordBlue")}>
      <div className={styles.bannerItem} onClick={() => selectedPromotionBanner("select_promotion")}>
        <span
          className={classnames(
            styles.itemText,
            "text-white font-regular-sm py-5"
          )}
          dangerouslySetInnerHTML={{
            __html: bannerData.bannertitle
          }}
        />
        {bannerData.bannerlinklabel && (
          <span
            className={classnames(
              styles.shopText,
              "text-white font-bold-sm py-5"
            )}
          >
            {bannerData.bannerlinkurl && (
              <Link href={bannerData.bannerlinkurl}>
                <a className="ms-4 pb-2">{bannerData.bannerlinklabel}</a>
              </Link>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

export default SlickBanner;
