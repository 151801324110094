import { useContext } from "react";
import { AppContext } from "../context";
import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";
import { notification } from "antd";
import { getText } from "../resources";

interface searchProps {
  setData?: any;
  localQuery?: string;
  isSearchPage?: boolean;
  isHagglePage?: boolean;
  isStore?: boolean;
  brand?: string;
  cancelTokenSourceRef?: any;
  isHeaderSearch?: boolean;
}

const useSearch = ({
  setData,
  localQuery,
  isSearchPage,
  isHagglePage,
  isStore,
  brand,
  cancelTokenSourceRef,
  isHeaderSearch,
}: searchProps) => {
  const { setDataLoading, isVendor, showNonActiveProduct, storeSearchValue, defaultPostZip} =
    useContext<any>(AppContext);
    const selectedBrand = encodeURIComponent(brand as string);
  const payload = isHagglePage
    ? `&search=${storeSearchValue}&only_haggle_products=1`
    : `&search=${storeSearchValue}&brand=${selectedBrand}`
  const getSearchItems = async () => {
  const searchParam = encodeURIComponent(localQuery as string)
    setDataLoading(true);
    try {
      await axios
        .get(
          `/api/ERP/search/searchSuggestion?limit=10${
            isVendor
              ? showNonActiveProduct
                ? `&show_non_active=true`
                : ""
              : ""
          }${
            isSearchPage
              ? `&search=${searchParam}`
              : isStore
              ? payload
              : isHeaderSearch
              ? searchParam
                ? `&search=${searchParam}`
                : `&only_haggle_products=1`
              : ""
          }${defaultPostZip && isVendor ? `&post_zip=${defaultPostZip}` : ""}`,
          {
            headers: { sessionId: getCookie("sessionid") },
            cancelToken: isHeaderSearch
              ? cancelTokenSourceRef.current.token
              : null,
          }
        )
        .then((response) => {
          setData(response?.data);
        });
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      } else {
        notification.error({
          message: getText().General.Messages.SomethingWrongTryAgain,
          duration: 4,
        });
      }
    }
    setDataLoading(false);
  };

  return {
    getSearchItems,
  };
};

export default useSearch;
