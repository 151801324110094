import { useContext } from "react";
import { AppContext } from "../context";
import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";
import { getText } from "../resources";
import { MixMatchVolumeProductsRepository } from "~/src/components/shared/CustomCarousel/CarouselRepository/fetchMixMatchVolumeProducts";

interface CarouselProps {
  setProductData: (arg: any[]) => void;
  setIsLoading: (arg: boolean) => void;
  productData: any[];
  title?: string;
  productSlug?: string;
  mainCategory?: string;
  subCategory?: string;
  modalShowSet: (arg: boolean) => void;
  id?: number;
}

const useCarousel = ({
  setProductData,
  setIsLoading,
  productData,
  title,
  productSlug,
  mainCategory,
  subCategory,
  modalShowSet,
  id,
}: CarouselProps) => {
  const {
    defaultPostZip,
    showNonActiveProduct,
    isVendor,
    customerStatus,
    setShowNoOffersModal,
    guestUser,
    shippingAddresses
  } = useContext<any>(AppContext);
  const isCompleteProfile =
  !guestUser && customerStatus?.is_authenticated &&
    (customerStatus?.profile_completed ||
      customerStatus?.is_admin ||
      customerStatus?.is_vendor);
  const addGoogleAnalytics = (event: string) => {
    const productItems = productData?.map((item, index) => ({
      item_id: item?.id?.toString(),
      item_name: item?.name,
      coupon: null,
      currency: "USD",
      discount: 0,
      index,
      item_brand: item?.brand_name || item?.brand?.name,
      item_category: item?.sub_category || item?.sub_category_name,
      item_category2: item?.main_category || item?.main_category_name,
      item_list_id: title,
      item_list_name: title,
      item_variant: item?.pack_size,
      price: item?.delivered_price,
    }));

    window?.dataLayer?.push({ ecommerce: null });
    window?.dataLayer?.push({
      event,
      ecommerce: {
        items: productItems,
      },
    });
  };
  let defaultShipping: any;
  shippingAddresses?.forEach((item: any) => {
    if (item["is_default"] === true) {
      defaultShipping = item;
    }
  });

  const createURL = (type: string, extraParams = "") => {
    let url = `/api/ERP/carouselProducts?type=${type}&limit=20${extraParams}`;
    const hasDefaultZipCode =    defaultPostZip &&
    (customerStatus?.is_vendor || customerStatus?.is_admin) &&
    customerStatus?.is_authenticated &&
    (!customerStatus?.profile_completed || !customerStatus?.email_verified ); 
    if (hasDefaultZipCode) {
      url += `&post_zip=${defaultPostZip}`;
    }
    if ((customerStatus?.is_vendor || customerStatus?.is_admin )) {
      url += `&post_zip=${defaultPostZip}`;
    }
    if (isVendor) {
      url += showNonActiveProduct ? "&show_non_active=true" : "";
    }
    return url;
  };

  const fetchProductData = async () => {
    if (isCompleteProfile) {
      setIsLoading(true);
      try {
        const url = createURL("featured");
        const response = await axios.get(url, {
          headers: { sessionId: getCookie("sessionid") },
        });
        setProductData(response?.data);
      } catch (error) {
        console.error(getText().General.Messages.SomethingWrongTryAgain)
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchRelatedProducts = async () => {
    if (isCompleteProfile) {
      setIsLoading(true);
      try {
        const extraParams = `${
          mainCategory
            ? mainCategory === "Food" || mainCategory === "Non-Food"
              ? mainCategory === "Food"
                ? `&food=true`
                : `&food=false`
              : `&category=${encodeURIComponent(mainCategory)}`
            : ""
        }${
          subCategory ? `&sub_category=${encodeURIComponent(subCategory)}` : ""
        }${productSlug ? `&exclude_id=${id}` : ""}`;
        const url = createURL("other", extraParams);
        const response = await axios.get(url, {
          headers: { sessionId: getCookie("sessionid") },
        });
        setProductData(response?.data);
      } catch (error) {
        console.error(getText().General.Messages.SomethingWrongTryAgain)
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchProductsByType = async (type: string, extraParams = "") => {
    setIsLoading(true);
    try {
      const url = createURL(type, extraParams);
      const response = await axios.get(url, {
        headers: { sessionId: getCookie("sessionid") },
      });
      setProductData(response?.data);
    } catch (error) {
      console.error(getText().General.Messages.SomethingWrongTryAgain)
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPopularThisMonth = async () => {
    const extraParams =
      mainCategory === "brands" ? `&brand=${subCategory}` : "";
    if (isCompleteProfile) {
      fetchProductsByType("popular", extraParams);
    }
  };

  const fetchRecommendedSearches = async () => {
    const extraParams = customerStatus?.customer_type
      ? `&customer_type=${customerStatus?.customer_type}`
      : "";
      fetchProductsByType("recommended_search", extraParams);
  };

  const fetchViewedItems = async () => {
    if (!guestUser && customerStatus?.is_authenticated) {
      fetchProductsByType("viewed");
    }
  };

  const fetchMixMatchVolumeProducts = async () => {
    setIsLoading(true);
    try {
      const results = await MixMatchVolumeProductsRepository.get(productSlug);
      if (results && results.length > 0) {
        setProductData(results);
      } else {
        modalShowSet(false);
        setShowNoOffersModal(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchHaggleRewards = async () => {
    if (isCompleteProfile) {
      fetchProductsByType("haggle");
    }
  };

  const fetchCrossPromotedProducts = async () => {
    //#TODO:
  };

  const fetchBestSellers = async () => {
    //#TODO:
  };
  return {
    fetchMixMatchVolumeProducts,
    fetchViewedItems,
    fetchRecommendedSearches,
    fetchPopularThisMonth,
    fetchRelatedProducts,
    fetchBestSellers,
    fetchCrossPromotedProducts,
    addGoogleAnalytics,
    fetchProductData,
    fetchHaggleRewards,
  };
};

export default useCarousel;
