import { notification } from "antd";
import axios from "axios";
import { useContext } from "react";
import { AppContext } from "~/src/libs/context";
import { getText } from "~/src/libs/resources";
import { getCookie } from "~/src/server/utils/commonUtils";
interface miniCartProps {
  setLoading: (args: boolean) => void;
  setMiniCartItems: (args: any) => void;
  setIsCheckoutDisabled: (args: boolean) => void;
  setMinOrderWeightItems: (args: []) => void;
  setMinOrderQtyItems: (args: []) => void;
  setUnavailableItems: (args: []) => void;
  miniCartItems: any;
}
const useMiniCart = ({
  setLoading,
  setMiniCartItems,
  setIsCheckoutDisabled,
  setMinOrderWeightItems,
  setMinOrderQtyItems,
  setUnavailableItems,
  miniCartItems,
}: miniCartProps) => {
  const { defaultPostZip, couponsName,setCartCount, customerStatus } = useContext<any>(AppContext);

  const getMiniCartItems = async () => {
    setLoading(true)
    try {
      let url = `/api/ERP/cart?mini_cart=True`;
      if (defaultPostZip &&  (customerStatus?.is_vendor ||  customerStatus?.is_admin)) {
        url += `&post_zip=${defaultPostZip}`;
      }
      await axios
        .get(url, {
          headers: { sessionId: getCookie("sessionid") },
        })
        .then((res) => {
          setIsCheckoutDisabled(false);
          setMiniCartItems(res?.data);
          setLoading(false);
          const pallets = res?.data?.pallets;
          const unavailableItemsArr: any = [];
          const minOrderWeightArr: any = [];
          const minOrderQtyArr: any = [];
          pallets?.forEach((item: any) => {
            if (!item?.is_available) {
              setIsCheckoutDisabled(true);
              unavailableItemsArr.push(item);
            }
            if (
              item?.is_available &&
              item?.vendor_pallet_weight < item?.min_order_weight
            ) {
              setIsCheckoutDisabled(true);
              minOrderWeightArr.push(item);
            }
            if (
              item?.is_available &&
              item?.vendor_pallet_quantity < item?.min_order_quantity
            ) {
              setIsCheckoutDisabled(true);
              minOrderQtyArr.push(item);
            }
          });
          setUnavailableItems(unavailableItemsArr);
          setMinOrderQtyItems(minOrderQtyArr);
          setMinOrderWeightItems(minOrderWeightArr);
        })
        .catch((e) => {
          notification.error({
            message: getText().General.Messages.SomethingWrongTryAgain,
            duration: 4,
          });
        });
        setLoading(false)
    } catch (e) {
      notification.error({
        message: getText().General.Messages.SomethingWrongTryAgain,
        duration: 4,
      });
      setLoading(false)
    }
    setLoading(false)
  };
  const moveToSavedForLater = async (id: number) => {
    try {
      await axios.post("/api/ERP/cart/saveForLater", {
        headers: { sessionId: getCookie("sessionid") },
        data: { pallet: id },
      });
      notification.success({
        message: getText().Cart.Card.savedForLaterMsg,
        duration: 4,
      });
      getMiniCartItems();
    } catch (error) {
      notification.error({
        message: getText().Cart.Card.errorInLaterApi,
        duration: 4,
      });
    }
  };

  const viewCartHandler = (event: string) => {
    const cartItems: any = [];
    miniCartItems?.pallets?.forEach(function (item: any, index: number) {
      if (item?.pallet_type === "PALLET") {
        const itemInfo = {
          item_id: item?.pallet_items?.[0]?.product?.id?.toString(),
          item_name: item?.pallet_items?.[0]?.product?.name,
          coupon: couponsName,
          currency: "USD",
          discount: Number(
            (item?.total_delivered_discount / item?.quantity).toFixed(2)
          ),
          index: index,
          item_brand: item?.pallet_items?.[0]?.product?.brand?.name,
          item_category: item?.pallet_items?.[0]?.product?.main_category_name,
          item_category2: item?.pallet_items?.[0]?.product?.sub_category_name,
          item_category3: "",
          item_category4: "",
          item_list_id: "",
          item_list_name: item?.pallet_items?.[0]?.product?.sub_category_name,
          item_variant: item?.pallet_items?.[0]?.product?.pack_size,
          price: Number(
            (item?.total_delivered_price / item?.quantity).toFixed(2)
          ),
          quantity: item?.quantity,
        };
        cartItems?.push(itemInfo);
      } else {
        const itemInfoMixed = {
          item_id: item?.id?.toString(),
          item_name: "Mixed Pallet",
          coupon: couponsName,
          currency: "USD",
          discount: Number(
            (item?.total_delivered_discount / item?.quantity).toFixed(2)
          ),
          index: index,
          item_brand: item?.sold_by_vendor,
          item_category: item?.pallet_items?.[0]?.product?.main_category_name,
          item_category2: item?.pallet_items?.[0]?.product?.sub_category_name,
          item_category3: "",
          item_category4: "",
          item_list_id: "",
          item_list_name: item?.pallet_items?.[0]?.product?.sub_category_name,
          item_variant: "mixed pallet",
          price: Number(
            (item?.total_delivered_price / item?.quantity).toFixed(2)
          ),
          quantity: item?.quantity,
          selected_product1: item?.pallet_items?.[0]?.product?.name || "",
          selected_product2: item?.pallet_items?.[1]?.product?.name || "",
          selected_product3: item?.pallet_items?.[2]?.product?.name || "",
          selected_product4: item?.pallet_items?.[3]?.product?.name || "",
          selected_product5: item?.pallet_items?.[4]?.product?.name || "",
          selected_product6: item?.pallet_items?.[5]?.product?.name || "",
          selected_product7: item?.pallet_items?.[6]?.product?.name || "",
          selected_product8: item?.pallet_items?.[7]?.product?.name || "",
          selected_product9: item?.pallet_items?.[8]?.product?.name || "",
          selected_product10: item?.pallet_items?.[9]?.product?.name || "",
          quantity1: item?.pallet_items?.[0]?.case_quantity || "",
          quantity2: item?.pallet_items?.[1]?.case_quantity || "",
          quantity3: item?.pallet_items?.[2]?.case_quantity || "",
          quantity4: item?.pallet_items?.[3]?.case_quantity || "",
          quantity5: item?.pallet_items?.[4]?.case_quantity || "",
          quantity6: item?.pallet_items?.[5]?.case_quantity || "",
          quantity7: item?.pallet_items?.[6]?.case_quantity || "",
          quantity8: item?.pallet_items?.[7]?.case_quantity || "",
          quantity9: item?.pallet_items?.[8]?.case_quantity || "",
          quantity10: item?.pallet_items?.[9]?.case_quantity || "",
        };
        cartItems?.push(itemInfoMixed);
      }
    });
    window?.dataLayer?.push({ ecommerce: null });
    window?.dataLayer?.push({
      event: event,
      ecommerce: {
        currency: "USD",
        value: miniCartItems?.grand_total_delivered_price,
        items: cartItems,
      },
    });
  };

  const getCartCount = async () => {
    try {
      let url = "/api/ERP/cart/cartCount";
      await axios
        .get(url, {
          headers: { sessionId: getCookie("sessionid") },
        })
        .then((res) => {
          setCartCount(res?.data?.pallet_count);
        })
        .catch((e) => {
          notification.error({
            message: getText().General.Messages.SomethingWrongTryAgain,
            duration: 4,
          });
        });
    } catch (e) {
      notification.error({
        message: getText().General.Messages.SomethingWrongTryAgain,
        duration: 4,
      });
    }
  };

  return {
    getCartCount,
    viewCartHandler,
    moveToSavedForLater,
    getMiniCartItems,
  };
};

export default useMiniCart;
