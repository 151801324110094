import Link from "next/link";
import styles from "./styles.module.scss";
import { AppContext } from "~/src/libs/context";
import classnames from "classnames";
import { storeLocatorProps } from "./types";
import { useContext, useState, useEffect } from "react";
import Icon from "@components/shared/Icon";
import CustomButton from "@components/shared/Button";
import Script from "next/script";
import SearchZipcodeIcon from 'public/icons/src/search_zipcode.svg';
import {
  Col,
  Dropdown,
  MenuProps,
  notification,
  Row,
  Skeleton,
  Space,
  Modal,
  Form,
  Switch,
} from "antd";
import Overlay from "../../Overlay";
import { useRouter } from "next/router";
import axios from "axios";
import { getCookie } from "~/src/server/utils/commonUtils";
import Field from "@components/shared/Fields";
import AccountInformation from "~/src/components/organsim/AccountPage/AccountInformation";
import { getText } from "~/src/libs/resources";
import useZipCode from "~/src/utilities/hooks/useZipCode";

const HeaderStoreLocator = ({
  Mode,
  guestLabel,
  headerLinks,
  onClose,
  MoveHaggleLinks,
}: storeLocatorProps) => {
  const {
    guestUser,
    shippingAddresses,
    getShippingAddresses,
    setAccountActiveTab,
    isMainMenuOpen,
    setMainMenuOpen,
    customerStatus,
    googlePlacesScript,
    setGooglePlacesScript,
    setContent,
    setCurrent,
    defaultPostZip,
    isStatusLoading,
    isVendor,
    showNonActiveProduct,
    setShowNonActiveProduct,
    toggleLoader,
    completeProfileLink,
    showZipCodeDialog,
    setShowZipCodeDialog,
    setDefaultPostZip,
  } = useContext<any>(AppContext);

  const [orderState, setOrderState] = useState("Name");
  const [addressText, SetAddress] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<any>();
  const [isGuestUser, setIsGuestUser] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [viewAddressesPermission, setViewAddressesPermission] =
    useState<boolean>(false);
  const [profileCompleted, setProfileCompleted] = useState<boolean>(true);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const [form] = Form.useForm();
  const handleToggleChange = () => {
    setShowNonActiveProduct(!showNonActiveProduct);
  };
  const { changeZipCode } = useZipCode();
  const [zipCode, setZipCode] = useState('');

  const [showAllAddresses, setShowAllAddresses] = useState(false);

  const toggleShowAllAddresses = () => {
    setShowAllAddresses(!showAllAddresses);
  };

  // Ensure userInfo is defined
  const visibleAddresses = userInfo ? (showAllAddresses ? userInfo : userInfo.slice(0, 3)) : [];

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZipCode(e.target.value);
  };
  const handleZipCodeSearch = () => {
    changeZipCode(zipCode, false);
    setZipCode('');
    setMainMenuOpen(false); // Close the dropdown
  };
  
  useEffect(() => {
    if (Object.keys(customerStatus).length !== 0 && isStatusLoading === false) {
      if (!customerStatus?.is_vendor) {
        if (!customerStatus?.profile_completed) {
          SetAddress("Please complete your profile to see delivered prices.");
          setProfileCompleted(false);
          setIsLoading(false);
        } else {
          setProfileCompleted(true);
          if (
            customerStatus?.permissions?.includes("VIEW_SHIPPING_ADDRESSES") &&
            customerStatus?.has_addresses
          ) {
            setViewAddressesPermission(true);
          } else {
            setViewAddressesPermission(false);
            SetAddress(`Delivered prices for zip code: ${defaultPostZip}`);
            setIsLoading(false);
          }
        }
      } else if (
        customerStatus?.is_vendor &&
        customerStatus?.has_addresses &&
        customerStatus?.profile_completed &&
        customerStatus?.permissions?.includes("VIEW_SHIPPING_ADDRESSES")
      ) {
        setViewAddressesPermission(true);
      } else if (customerStatus?.is_vendor) {
        setViewAddressesPermission(false);
        SetAddress(`Delivered prices for zip code: ${defaultPostZip}`);
        setIsLoading(false);
      }
    }
  }, [customerStatus, defaultPostZip, isStatusLoading]);
  const handleOrderMenuChange = (e: any) => {
    setOrderState(e?.key);
    switch (e?.key) {
      case "Zip Code":
        const orderByZip = userInfo?.sort((a: any, b: any) => {
          if (a.post_zip < b.post_zip) return -1;
          if (a.post_zip > b.post_zip) return 1;
          return 0;
        });
        setUserInfo(orderByZip);
        break;
      case "Name":
        const orderByName = userInfo?.sort((a: any, b: any) => {
          if (a.city < b.city) return -1;
          if (a.city > b.city) return 1;
          return 0;
        });
        setUserInfo(orderByName);
        break;
      case "State":
        const orderByState = userInfo?.sort((a: any, b: any) => {
          if (a.state < b.state) return -1;
          if (a.state > b.state) return 1;
          return 0;
        });
        setUserInfo(orderByState);
        break;
    }
  };
  const router = useRouter();
  const setDefaultAddress = async (addressId: number) => {
    try {
      await axios.patch(`/api/ERP/customer/shippingAddress/${addressId}`, {
        headers: { sessionId: getCookie("sessionid") },
        data: { is_default: true },
      });
      notification.success({
        message: `Your address has been successfully updated!`,
        duration: 2,
      });
      setMainMenuOpen(false);
      getShippingAddresses();
    } catch (e: any) {
      setMainMenuOpen(false);
      if (e?.response?.status === 400) {
        notification.error({
          message:
            "Please update your address and ensure all required fields are filled.",
          duration: 2,
        });
      } else {
        notification.error({
          message: getText().General.Messages.SomethingWrongTryAgain,
          duration: 2,
        });
      }
    }
  };
  const changeAddressHandler = (address: any, id: any) => {
    address.split(",")[3] ? setDefaultPostZip(address.split(",")[3].trim()) : setDefaultPostZip("");
    SetAddress(address);
    setDefaultAddress(id);
  };
  const addAddressHandler = () => {
    if (typeof onClose === "function") onClose();
    setMainMenuOpen(false);
    setAccountActiveTab("myAddresses");
    router.push("/account/accountInfo");
    setCurrent("accountInfo");
    setContent(<AccountInformation />);
  };
  const orderItems: any = [
    {
      key: "Name",
      label: <p className="font-regular-sm mb-0">{getText()?.Header?.name}</p>,
    },
    {
      key: "State",
      label: <p className="font-regular-sm mb-0">{getText()?.Header?.state}</p>,
    },
    {
      key: "Zip Code",
      label: (
        <p className="font-regular-sm mb-0">{getText()?.Header?.zipCode}</p>
      ),
    },
  ];
  const items: MenuProps["items"] = [
  {
    key: "addresses",
    label: (
      <div className={styles.antDropdownOverrides}>
        {visibleAddresses?.map((item: any, index: number) => {
          const address = `${item?.city}, ${item?.state}, ${item?.country}, ${item?.post_zip}`;
          const isSelected = item?.is_default; // Assuming this marks the selected address
          return (
            <Row
              className={`${styles.addressRow} ${index === 0 ? styles.firstAddressRow : ''}`}
              justify="space-between"
              key={index}
              onClick={() => changeAddressHandler(address, item?.id)}
              style={{
                width: '100%',
                margin: 0,
                padding: '12px 50px', // Apply consistent padding here
              }}
            >
              <Col className={styles.address}>
                <span className={styles.city}>{item?.city}</span>, {item?.state}, {item?.post_zip}
              </Col>
            </Row>
          );
        })}
      </div>
    ),
  },
  {
    type: "divider",
  },
  {
    key: "addAddress",
    label: (
       <Row className={styles.addAddressRow}>
          {userInfo && userInfo.length > 3 && !showAllAddresses && (
            <>
              <Col>
                <button className={`${styles.addAddressButton} ${styles.viewMoreButton}`} onClick={toggleShowAllAddresses}>
                  View More
                </button>
              </Col>
              <Col className={styles.verticalDivider}></Col>
            </>
          )}
          <Col>
            <button className={styles.addAddressButton} onClick={addAddressHandler}>
              Add a New Address
            </button>
          </Col>
        </Row>
    ),
  },
  ...((isVendor || !viewAddressesPermission)
    ? [
        {
          key: "zipCode",
          label: (
            <div className={styles.zipCodeBox}>
              <h3 className={styles.zipCodeHeading}>Search by zipcode</h3>
              <p className={styles.zipCodeSubtitle}>
                Enter a zipcode to see product availability and delivery options.
              </p>
              <div className={styles.zipCodeInputWrapper}>
                <input
                  type="text"
                  className={styles.zipCodeInput}
                  placeholder="Enter Zipcode"
                  value={zipCode}
                  onChange={handleZipCodeChange}
                />
                <button
                  className={styles.zipCodeSearchButton}
                  onClick={handleZipCodeSearch}
                >
                  <SearchZipcodeIcon />
                </button>
              </div>
              <p className={styles.zipCodeDisclaimer}>
                Prices shown by zipcode search exclude delivery accessorial fees.
              </p>
            </div>
          ),
        },
      ]
    : []), // If not a vendor, do not include this section
];



  const fetchAddress = async () => {
    setIsLoading(true);
    try {
      setShowMenu(shippingAddresses?.length > 0 ? true : false);
      if (shippingAddresses?.length > 0) {
        const copiedAddresses = [...shippingAddresses];
        const orderByName = copiedAddresses?.sort((a: any, b: any) => {
          if (a.city < b.city) return -1;
          if (a.city > b.city) return 1;
          return 0;
        });
        setUserInfo(orderByName);
        const defaultAddress = copiedAddresses?.find(
          (item: any) => item?.is_default === true
        );
        const address = `${defaultAddress?.city}, ${defaultAddress?.state}, ${defaultAddress?.country}, ${defaultAddress?.post_zip}`;
        SetAddress(address);
      }
      setIsLoading(false);
    } catch (error: any) {
      if (error?.response?.status !== 403) {
        notification.error({
          message: getText().General.Messages.SomethingWrongTryAgain,
          duration: 4,
        });
      }
    }
    setIsLoading(false);
  };

  let defaultShipping: any;
  shippingAddresses?.forEach((item: any) => {
    if (item["is_default"] === true) {
      defaultShipping = item;
    }
  });

  useEffect(() => {
    if (
      !guestUser &&
      isStatusLoading === false &&
      viewAddressesPermission &&
      profileCompleted
    )
      fetchAddress();
    setIsGuestUser(guestUser);
  }, [shippingAddresses, viewAddressesPermission, isStatusLoading]);

  const onCancel = () => {
    setShowZipCodeDialog(false);
    form?.resetFields();
  };
  const onOk = () => {
    form?.validateFields(["post_zip"]);
    form?.getFieldValue("post_zip") &&
      changeZipCode(form?.getFieldValue("post_zip"), false);
  };
  return (
    <div
      className={classnames(
        "bg-white",
        Mode === "vertical"
          ? styles.mobileLocatorContainer
          : styles.locatorContainer,
        "font-bolder-sm ",
        "container "
      )}
    >
      {!googlePlacesScript && (
        <Script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_PLEACES_KEY}&libraries=places`}
          onLoad={() => {
            setGooglePlacesScript(true);
          }}
        />
      )}
      <div className={styles.itemWrapper}>
        {!isGuestUser ? (
          <>
            {isLoading ? (
              <Skeleton.Input active className="py-4" />
            ) : (
              <>
                {isMainMenuOpen && (
                  <Overlay
                    closeMenu={() => setMainMenuOpen(false)}
                    bgOverlay="mobileLocator"
                  />
                )}
                <div
                  className="font-bolder-xs text-oxfordBlue"
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={showMenu ? () => setMainMenuOpen(true) : () => {}}
                >
                  <Dropdown
                    menu={{ items }}
                    overlayClassName={styles.antDropdownOverrides}
                    open={isMainMenuOpen}
                  >
                    <CustomButton
                      onClick={(e: any) => e.preventDefault()}
                      className={classnames(
                        styles.dropdownContent,
                        "font-bold-xs"
                      )}
                      theme="primary"
                    >
                      <Space
                        className={classnames(styles.selectedAddress, "px-2")}
                        onClick={() => {
                          if (!profileCompleted)
                            router?.push(completeProfileLink);
                          else if (!viewAddressesPermission)
                            setShowConfirmationDialog(true);
                        }}
                      >
                        <>
                          <Icon
                            name="location-arrow"
                            width={16}
                            height={16}
                            color="#076DDC"
                          />
                          <div
                            className={
                              !profileCompleted
                                ? `font-medium-xs text-oxfordBlue ms-2`
                                : ""
                            }
                          >
                            {addressText?.split(",")[3] ? (addressText!.split(",")[3].trim()) !==defaultPostZip ? defaultPostZip: addressText : addressText}
                        
                          </div>
                          <div className={styles.iconWrapper}>
                            {profileCompleted ? (
                              <Icon name="down" width={12} height={12} />
                            ) : null}
                          </div>
                        </>
                      </Space>
                    </CustomButton>
                  </Dropdown>
                  {!viewAddressesPermission ? (
                    <>
                      <Modal
                        open={showConfirmationDialog}
                        title="Change zipcode Confirmation"
                        onCancel={() => setShowConfirmationDialog(false)}
                        onOk={() => {
                          setShowConfirmationDialog(false);
                          setShowZipCodeDialog(true);
                        }}
                        okText="Change it"
                        cancelText="Don't change"
                      >
                        <div>
                          <label>
                            {`All delivered prices are shown for your zip code 
                            ${defaultPostZip} `}
                            .
                            <br />
                            {getText()?.Header?.changeDeliveryZipCodeMessage}
                          </label>
                        </div>
                      </Modal>
                      <Modal
                        open={showZipCodeDialog}
                        title="Enter zip code for delivered pricing"
                        onCancel={onCancel}
                        onOk={onOk}
                        okText="Get Started"
                        cancelText="Cancel"
                      >
                        <Form className={"my-10 w-50"} form={form}>
                          <Form.Item
                            name="post_zip"
                            rules={[
                              {
                                required: true,
                                message: "This field should not be empty",
                              },
                            ]}
                          >
                            <Field
                              className={"manageAccountField"}
                              placeholder="Zip code"
                            />
                          </Form.Item>
                        </Form>
                      </Modal>
                    </>
                  ) : null}
                </div>
              </>
            )}
          </>
        ) : (
          <div className={`${styles.storeLocatorIcon} ms-6`}>
            <Icon name="info" width={11} height={11} />
            {guestLabel && (
              <button
                className={`${styles.storeLocatorBtn} font-medium-xs text-oxfordBlue ms-2`}
                onClick={() => {
                  router?.push(
                    `/?epallet_login=true&source_path=${router?.asPath?.replace(
                      "&",
                      "__"
                    )}`
                  );
                }}
              >
                {guestLabel}
              </button>
            )}
          </div>
        )}
      </div>

      {(headerLinks || isVendor || viewAddressesPermission) && (
        <div className={styles.menu}>
          {isVendor && (
            <div
              className={`${styles.vendorSwitch} p-8 text-oxfordBlue font-regular-xs`}
            >
              <div className="me-4">
                {getText()?.Header?.inactiveProductMessage}
              </div>
              <Switch
                checked={showNonActiveProduct}
                onChange={handleToggleChange}
                loading={toggleLoader}
              />
            </div>
          )}
          {/* {MoveHaggleLinks?.map((item: any, index: number) => (
            <>
              {item?.moveLinkUrl ? (
                <Link href={item?.moveLinkUrl} key={index}>
                  <a
                    target="_blank"
                    href={item?.moveLinkUrl}
                    className="text-oxfordBlue font-regular-xs"
                    onClick={onClose}
                    rel="noopener noreferrer"
                  >
                    {customerStatus?.is_move_customer
                      ? item?.moveLabelForExistingUser
                      : item?.moveLabelForNewUser}
                  </a>
                </Link>
              ) : null}
              {isGuestUser || customerStatus?.is_authenticated ? (
                <>
                  {item?.haggleLinkUrl && (
                    <Link
                      href={
                        customerStatus?.is_haggle_customer
                          ? "/account/haggleRewards"
                          : item?.haggleLinkUrl
                      }
                      key={index}
                    >
                      <a
                        className="text-oxfordBlue font-regular-xs"
                        onClick={onClose}
                      >
                        {customerStatus?.is_haggle_customer
                          ? item?.haggleLabelForExistingUser
                          : item?.haggleLabelForNewUser}
                      </a>
                    </Link>
                  )}
                </>
              ) : <Skeleton.Button size="small" shape="square" style={{width: "92px"}} />
              }
            </>
          ))} */}
          {!isStatusLoading ? (
            <>
              {headerLinks?.map((item: any, index: any) => (
                <>
                  {!(item?.linkUrl?.includes("epallet_signup") && isVendor) ? (
                    <Link href={item?.linkUrl} key={index}>
                      <a
                        className={
                          item?.linkUrl?.includes("epallet_signup")
                            ? "font-semibold-sm"
                            : "text-oxfordBlue font-regular-xs"
                        }
                        onClick={onClose}
                      >
                        {item?.linkLabel}
                      </a>
                    </Link>
                  ) : null}
                </>
              ))}
            </>
          ) : (
            <Skeleton.Input active className="py-4" />
          )}
        </div>
      )}
    </div>
  );
};
export default HeaderStoreLocator;
