import React, { useEffect, useState } from "react";
import { Table } from "antd";
import styles from "./styles.module.scss";
import HaggleModal from "../HaggleModal/HaggleModal";
import Button from "../Button";

interface TableRow {
  title: string;
  description: string;
  all: string;
  tier1: string;
  tier2: string;
  tier3: string;
}

interface HaggleTableData {
  tableTitle: string;
  tableRows: TableRow[];
  disclaimerMessage: string;
}

const HaggleTable = ({ modal }: { modal?: boolean }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState<HaggleTableData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/hubspot-cms/haggle-table-cms");
        if (!response.ok) {
          throw new Error("Failed to fetch Haggle table data.");
        }
        const result = await response.json();
        setData(result);
      } catch (err: any) {
        setError(err.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const columns: any = [
    {
      title: "HAGGLE",
      dataIndex: "title",
      key: "title",
      fixed: "left",
      minWidth: "142px",
      render: (text: string, record: TableRow) => (
        <div className="py-4">
          <h2 className="font-bold-h5 text-darkGray mb-1">{record.title}</h2>
          <p className="text-darkGray mb-0">{record.description}</p>
        </div>
      ),
    },
    { title: "All", dataIndex: "all", key: "all" },
    { title: "Tier 1", dataIndex: "tier1", key: "tier1" },
    { title: "Tier 2", dataIndex: "tier2", key: "tier2" },
    { title: "Tier 3", dataIndex: "tier3", key: "tier3" },
  ];

  const dataSource = data?.tableRows.map((row, index) => ({
    key: index,
    ...row,
  }));

  return (
    <div className={`container ${styles.haggleTableWrapper}`}>
      <HaggleModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
      {data?.tableTitle && (
        <h1
          className={`font-bold-h1 text-center mb-33 mt-40 pt-3 ${styles.tableTitle}`}
        >
          {data.tableTitle}
        </h1>
      )}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        className={styles.haggleTable}
        rowClassName={styles.tableRow}
        scroll={{ x: true }}
      />
      {data?.disclaimerMessage && (
        <p
          className={`pt-20 mb-24 ps-8 font-medium-xs ${styles.disclaimerMessage}`}
        >
          {data.disclaimerMessage}
        </p>
      )}
      {!modal && (
        <div className={`w-100 text-center ${styles.buttonWrapper}`}>
          <Button
            className="mt-2 font-bold-base p-8 h-auto px-21"
            text="Schedule Demo"
            theme="primary"
            onClick={handleOpenModal}
          />
        </div>
      )}
    </div>
  );
};

export default HaggleTable;
