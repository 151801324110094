import { notification } from "antd";
import axios from "axios";
import { useContext } from "react";
import { getCookie } from "src/server/utils/commonUtils";
import { DEFAULT_ZIP_CODE } from "~/src/constants";
import { AppContext } from "~/src/libs/context";

const useZipCode = () => {
  const { setDefaultPostZip, setShowZipCodeDialog } = useContext<any>(AppContext);
  const changeZipCode = async (
    zipCode: string,
    isGeolcationZipcode: boolean
  ) => {
    try {
      const response = await axios.post(`/api/ERP/common/zipCode`, {
        headers: { sessionId: getCookie("sessionid") },
        data: {
          country: null,
          post_zip: zipCode,
        },
      });
      !isGeolcationZipcode &&
        notification.success({
          message: `Your zip code has been successfully updated!`,
          duration: 3,
        });
      setDefaultPostZip(response?.data?.post_zip);
    } catch (error: any) {
      if (!isGeolcationZipcode)
        notification.error({
          message:
            error?.response?.data?.detail ||
            "Can not change your zipcode, please try later.",
          duration: 3,
        });
      else {
        setDefaultPostZip(DEFAULT_ZIP_CODE);
      }
    }
    setShowZipCodeDialog(false);
  };
  return {
    changeZipCode,
  };
};
export default useZipCode;